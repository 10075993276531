/**
 * Created by ZengFanhui on 2021/1/4 16:47
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取检测报告列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetTestReportList (type = -1, pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    type,
    pageIndex,
    pageSize
  }
  return doHttp('/trace/testReport/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑检测报告
 * @param params
 * @returns {*}
 */
export function apiEditTestReport(params) {
  return doHttp('/trace/testReport/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/testReport/delete.action', HTTP_TYPE.GET, params)
}
