<template>
  <div>
    <el-upload
      :class="{'not-upload': fileList.length >= limit}"
      ref="upload"
      list-type="picture-card"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :data="extDate"
      :accept="accept"
      :action="fileUploadUrl">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <div class="file-wrapper">
          <img v-if="file.isImage" class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          <div v-else class="file">
            <div style="font-weight: 600">{{file.ext}}文件</div>
            <div>{{file.name}}</div>
          </div>
        </div>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
  </div>
</template>

<script type="text/ecmascript-6">
  import {fileUploadUrl} from '../../api/file'
  import {handleFileExtByName} from '../../utils/common'

  export default {
    name: 'UploadImage',
    props: {
      limit: {
        type: Number,
        default: 9
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      rename: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        fileUploadUrl,
        fileList: [],
        extDate: {}
      }
    },
    created() {
    },
    methods: {
      beforeUpload(file) {
        this.loadingInstance = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(243, 243, 243, 0.1)'
        })
        // 检验文件是否重复
        const existFile = this.fileList.find(item => file.name === item.name || file.name === item.response.data)
        if (existFile) {
          this.$message({
            type: 'error',
            message: `文件${existFile.name || existFile.response.data}已存在`
          })
          this.loadingInstance.close()
          return false
        }
        const fileInfo = handleFileExtByName(file.name)
        // 如果不需要重命名
        if (!this.rename) {
          // 但是图片还是要重命名
          if (fileInfo.isImage) {
            this.extDate = {
              needRename: 1
            }
          } else {
            this.extDate = {
              needRename: 0
            }
          }
        }
        return new Promise((resolve, reject) => {
          resolve(this.extDate)
        })
      },
      // 上传失败
      uploadError(err, file, fileList) {
        this.loadingInstance.close()
        this.$message({
          type: 'warning',
          message: err
        })
      },
      // 上传成功
      uploadSuccess(response, file, fileList) {
        this.loadingInstance.close()
        if (response.result === 0) {
          const fileName = file.name
          const fileInfo = handleFileExtByName(fileName)
          file = Object.assign(file, fileInfo)
          this.fileList.push(file)
          this.$message({
            type: 'success',
            message: '上传成功'
          })
          this.$emit('upload-success', response.data, this.fileList)
        } else {
          this.$message({
            type: 'warning',
            message: response.msg
          })
        }
      },
      handleRemove(file) {
        const fileuid = file.uid
        const fileIndex = this.fileList.findIndex(item => item.uid === fileuid)
        this.fileList.splice(fileIndex, 1)
        this.$emit('delete', file.response.data, this.fileList)
      },
      // 清除所有图片
      clearFile() {
        this.fileList = []
        this.$refs['upload'].clearFiles()
      },
      // 设置图片
      setFile(file, fileURL) {
        let fileList = []
        if (fileURL.length !== 0) {
          if (file.indexOf(',') !== -1) {
            file = file.split(',')
          } else {
            file = [file]
          }
          for (let i = 0; i < fileURL.length; i++) {
            const fileInfo = handleFileExtByName(file[i])
            const fileResult = Object.assign({}, fileInfo, {
              url: fileURL[i],
              response: {
                data: file[i]
              },
              name: file[i]
            })
            fileList.push(fileResult)
          }
        }
        this.fileList = fileList
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .not-upload >>> .el-upload--picture-card
    display none
  .file-wrapper
    display flex
    align-items center
    justify-content center
    height 148px
    .file
      padding 10px
</style>
