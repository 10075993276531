<template>
  <div>
    <mt-button class="mb15">
      <el-select
        style="margin-right: 15px"
        v-model="type"
        collapse-tags
        @change="onTypeSearchChange"
        placeholder="请选择类别">
        <el-option
          :label="saleTypeAll.name"
          :value="saleTypeAll.id">
        </el-option>
        <el-option
          v-for="item in saleTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      ref="visitTable"
      :data="testReportList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      style="width: 100%;">
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="testDate"
        label="检测日期">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        width="300"
        prop="typeName"
        label="类型">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="图片">
        <template slot-scope="scope">
          <el-button @click="showImage(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="100"
        label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="testReportTotal"
      class="pagination">
    </el-pagination>

    <el-dialog title="查看报告" :visible.sync="showImageVisible" width="50%">
      <el-form label-position="left" label-width="100px" class="data-form" style='width: 600px; margin:0 auto;'>
        <el-form-item label="图片">
          <mt-image-preview
            :images="previewImages"
            :is-show-image-list="true"></mt-image-preview>
        </el-form-item>
        <el-form-item label="文件">
          <a v-for="item in previewFiles" :key="item.url" :href="item.url" target="_blank">
            <div class="file-preview-wrapper">
              <i class="el-icon-document"></i>
              <div class="file-name">{{item.name}}</div>
            </div>
          </a>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showImageVisible = false">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="model.id === 0 ? '新增报告' : '编辑报告'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 600px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="检测日期" prop="testDate">
          <el-date-picker
            v-model="model.testDate"
            type="date"
            placeholder="选择检测日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            style="margin-right: 15px"
            v-model="model.type"
            collapse-tags
            placeholder="请选择类别">
            <el-option
              v-for="item in saleTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件" required prop="picture">
          <mt-upload-file ref="fileUpload" accept="image/*, application/pdf" :rename="false" :limit="9"
                          @upload-success="uploadSuccess" @delete="deleteFile"></mt-upload-file>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSalesroom">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import MtImagePreview from '../../components/mt-image-preview'
  import MtUploadFile from '../../components/mt-upload-file'
  import {tableMixin} from '../../mixins/table'
  import {apiGetTestReportList, apiEditTestReport, apiDeleteById} from '../../api/testReport'
  import {apiGetSaleTypeList} from '../../api/saleType'
  import {handleFileExtByName} from '../../utils/common'

  export default {
    name: 'TraceTestReport',
    mixins: [tableMixin],
    components: {MtButton, MtImagePreview, MtUploadFile},
    data() {
      return {
        testReportList: [],
        testReportTotal: 0,
        saleTypeAll: {
          id: -1,
          name: '全部'
        },
        saleTypeList: [],
        type: -1,
        dialogVisible: false,
        model: {
          id: 0,
          name: '',
          type: '',
          picture: '',
          testDate: ''
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}],
          testDate: [{required: true, message: '不能为空', trigger: 'blur'}],
          type: [{required: true, message: '不能为空', trigger: 'blur'}],
          picture: [{required: true, message: '不能为空', trigger: 'change'}]
        },
        previewImages: [],
        previewFiles: [],
        showImageVisible: false
      }
    },
    created() {
      this._getSaleTypeList()
    },
    activated() {
      this._getTestReportList()
    },
    methods: {
      onTypeSearchChange(val) {
        this.pageIndex = 1
        this._getTestReportList(val)
      },
      onAddBtnClick() {
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this.dialogVisible = true
        this._clearModel()
        this.model = Object.assign({}, this.model, row)
        this.$nextTick(() => {
          this.$refs['fileUpload'].setFile(row.picture, row.pictureURL)
        })
      },
      onRefreshBtnClick() {
        this._getTestReportList()
      },
      editSalesroom() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            apiEditTestReport(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getTestReportList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      uploadSuccess(fileName, fileList) {
        this.model.picture = this._handleFileList(fileList)
        this.$refs['dataForm'].clearValidate('picture')
      },
      deleteFile(fileName, fileList) {
        this.model.picture = this._handleFileList(fileList)
      },
      _handleFileList(fileList) {
        let result = []
        if (fileList.length !== 0) {
          for (let i = 0; i < fileList.length; i++) {
            result.push(fileList[i].response.data)
          }
        }
        return result.toString()
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该检测报告？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getTestReportList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      showImage(row) {
        const pictures = row.pictureURL
        let pictureList = []
        let fileList = []
        if (pictures.length !== 0) {
          const fileNameArr = row.picture.split(',')
          for (let i = 0; i < pictures.length; i++) {
            const fileInfo = handleFileExtByName(pictures[i])
            if (fileInfo.isImage) {
              pictureList.push(pictures[i])
            } else {
              fileList.push({
                url: pictures[i],
                name: fileNameArr[i]
              })
            }
          }
        }
        this.previewImages = pictureList
        this.previewFiles = fileList
        this.showImageVisible = true
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getTestReportList(val)
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
          this.$refs['fileUpload'].clearFile()
        })
      },
      _resetModel() {
        this.model = {
          id: 0,
          name: '',
          address: '',
          picture: '',
          chargePerson: ''
        }
      },
      _getTestReportList(type = this.type, pageIndex = this.pageIndex) {
        apiGetTestReportList(type, pageIndex).then(({data}) => {
          this.testReportList = data.data
          this.testReportTotal = data.total
        })
      },
      _getSaleTypeList() {
        apiGetSaleTypeList(1, 99999).then(({data}) => {
          this.saleTypeList = data.data
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .data-form >>> .el-form-item__content
    text-align left
  .file-preview-wrapper
    cursor pointer
    display flex
    align-items center
    padding 10px
    margin-bottom 10px
    border 1px solid
    border-radius 5px
    .file-name
      margin-left 10px
</style>
