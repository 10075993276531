<template>
  <div>
    <ul v-if="isShowImageList" class="img-wrapper">
      <li v-for="(item, index) in images" :key="index" class="item">
        <img @click="lookImages(index)" class="img" :src="item">
      </li>
    </ul>
    <div class="float-clear"></div>

    <el-dialog
      :modal-append-to-body="false"
      append-to-body
      @close="dialogClose"
      id="detailImageDialog"
      title="图片预览"
      :visible.sync="showDialog"
      custom-class="pic-look-dialog"
      fullscreen>
      <el-carousel
        ref="imageCarousel"
        :autoplay="false"
        height="100%"
        indicator-position="none">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <img :src="item" height="100%">
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    name: 'DetailImage',
    props: {
      images: {
        type: Array,
        default: function () {
          return []
        }
      },
      isShowImageList: {
        type: Boolean,
        default: true
      },
      dialogImageVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDialog: false
      }
    },
    watch: {
      dialogImageVisible(val) {
        this.showDialog = val
      }
    },
    methods: {
      dialogClose() {
        this.showDialog = false
        this.$emit('dialog-close')
      },
      lookImages(index) {
        this.showDialog = true
        this.$nextTick(() => {
          this.$refs.imageCarousel.setActiveItem(index)
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  #detailImageDialog >>> .el-dialog {
    background-color: rgba(0, 0, 0, 0.5);

    .el-dialog__header {
      background-color: rgba(0, 0, 0, 0.7);
      text-align: left;

      .el-dialog__title {
        color: rgba(255, 255, 255, 0.8);
      }

      .el-dialog__headerbtn {
        font-size: 28px;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .el-dialog__body {
      height: 80%;

      .el-carousel {
        width: 100%;
        height: 100%;

        .el-carousel__container {
          height: 100% !important;

          .el-carousel__item {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .img-wrapper {
    margin-bottom: 10px;
    width: 100%;

    .item {
      float: left;
      margin-right: 14px;
      margin-bottom: 15px;
      cursor: pointer;

      .img {
        width: 150px;
        height: 110px;
      }
    }
  }
</style>
